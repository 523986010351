.clients-card-container {
  width: 100%;
  background-color: rgb(255, 255, 255);

  border-top: 5px solid rgba(1, 31, 31, 0.767);
  padding: 60px;
  display: flex;
  flex-direction: column;
  z-index: 11;
}
.logo-container {
  display: flex;
  align-items: center;
}
.navigation {
  display: flex;
  gap: 16px;
}
.banner-header {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container .image {
  width: 40px;
  height: auto;
}
.banner-text-container {
  max-width: 600px;
  margin-top: 180px;
}
.client-logo-container {
  width: 80%;
  height: 80%;
}

.client-logo-container .image {
  filter: grayscale(100%);
  /* opacity: 0.3; */
  object-fit: contain;
}

.image-container {
  width: 800px;
  z-index: 111;
  position: absolute;
  top: 150px;
  left: 150px;
}

.section1-container {
  background-color: #eaf3f7;
}
.section1-container p:nth-child(1) {
  margin-bottom: 20px;
  margin-top: 16px;
}
.section1-container p:nth-child(2) {
  margin: 0;
}

.section1-container > .image-container {
  width: 100%;
}
.sec1-image-container {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.sec2-container {
  height: 500px;
}

.sec3-container {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  background: rgb(48, 100, 145);
  background: linear-gradient(
    90deg,
    rgba(48, 100, 145, 1) 0%,
    rgba(72, 131, 176, 1) 35%,
    rgba(126, 172, 160, 1) 100%
  );
  margin-top: 0;
  margin-top: 0;
  position: relative;
}
.sec3-container svg {
  margin-bottom: -20px;
  padding-bottom: -20px;
}

.sec3-img-container {
  width: auto;

  position: absolute;
  right: 100px;
  top: 0;
}

.sec3-text-container .header {
  color: white;
  font-size: 32px;
}

.sec-container {
  margin-top: 200px;
}
/* module section */

.module-container p {
  display: flex;
  align-items: center;
  gap: 16px;
  color: #676767;
}

/* blobs */
.svg-container {
  width: 800px;
  height: 800px;
  opacity: 0.4;
  position: absolute;
  bottom: -600px;
}

/* core feature section */

.core-feature-section {
  text-align: center;
  margin-top: 100px;
}

.core-feature-section p {
  /* color: #3567f3; */
  color: #014065;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 2px;
}

.core-feature-section h3 {
  font-weight: 700;
  font-size: 50px;
  margin-top: 0;
  text-transform: capitalize;
  margin-bottom: 40px;
}
.features-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.features-container > .icon {
  font-size: 90px;
  color: #3567f3;
}

.features-container > .icon-container {
  height: 200px;
}
.features-container > .icon-container > .img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.features-container > .text-details > h4 {
  margin-bottom: 0;
  font-size: 20px;
}
.features-container > .text-details > p {
  margin-top: 5px;
  font-size: 16px;
}

.contact-info-container {
  background-color: #3567f3;
  border-radius: 16px;
  display: flex;
  align-items: center;
  /* background-image: url("https://html.themeholy.com/webteck/demo/assets/img/bg/testimonial_bg_5.jpg"); */

  background: linear-gradient(#3567f3, #3567f3),
    url("https://html.themeholy.com/webteck/demo/assets/img/bg/testimonial_bg_5.jpg");
  background-blend-mode: hue;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 20px;
  color: white;
  height: 150px;
}

.contact-info > .icon {
  font-size: 60px;
  color: white;
}
.contact-info > .text-details > h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.contact-info > .text-details > p {
  margin-top: 5px;
  font-size: 30px;
  font-weight: 900;
}

/* about us section */

.about-us-main-wrapper {
  border: 1px solid rgba(128, 128, 128, 0.349);
  border-radius: 16px;
  position: relative;
  width: 100%;
  height: 100%;
}
.about-us-main-wrapper .img-container {
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
}

.about-us-main-wrapper .img-container > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  position: absolute;
}

.about-us-main-wrapper .text-details > h4 {
  color: #3567f3;
  font-size: 24px;
}

.about-us-main-wrapper .text-details > p:first-of-type {
  font-size: 50px;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 5px;
}

.about-us-main-wrapper .text-details > .list-container > .list {
  display: flex;
  align-items: center;
  gap: 16px;
}
.about-us-main-wrapper .text-details > .list-container > .list > .icon {
  color: #3567f3;
  font-size: 24px;
}

.about-us-main-wrapper .btn-container {
  padding-top: 20px;
}

/* what we do section  */

.what-we-do-wrapper {
  background-color: #e3eafc9c;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.what-we-do-wrapper .core-feature-section > h3 {
  margin-bottom: 50px;
}

.what-we-do-wrapper .card-wrapper {
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.3s ease-out;
}

.what-we-do-wrapper .card-wrapper:hover {
  transform: translateY(-20px);
}

.what-we-do-wrapper .card-wrapper > .content-container {
  padding: 50px 50px 10px 50px;
}
.what-we-do-wrapper .card-wrapper > .content-container .text {
  margin-bottom: 30px;
}
.what-we-do-wrapper .card-wrapper > .content-container > .icon-container {
  /* border: 1px solid #3568f33a; */
  width: 100px;
  height: 100px;
  display: grid;
  place-items: center;
  border-radius: 100%;
  margin-bottom: 30px;
  transition: 0.3s ease-out;
}

.what-we-do-wrapper
  .card-wrapper
  > .content-container
  > .icon-container
  > .icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.what-we-do-wrapper .card-wrapper > .content-container > .icon-container:hover {
  /* border: 1px solid #3567f3; */
}

.what-we-do-wrapper .card-wrapper > .content-container > .icon {
  font-size: 60px;
  color: #3567f3;
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 20px;
}
.what-we-do-wrapper .card-wrapper > .content-container > .heading {
  margin: 0;
  font-size: 24px;
  box-sizing: border-box;
  text-wrap: wrap;
  line-height: 1;
  /* height: 50px; */
  font-weight: 500;
}

.what-we-do-wrapper .card-wrapper > .content-container > .route {
  text-decoration: underline;
  color: #3567f3;
}

/* team container */

.team-container {
  height: 500px;
  width: 100%;
  background-color: #3567f3;
  margin-bottom: 100px;
  border-radius: 16px;
}

/* FAQs section */

.faqs-section {
  position: relative;
}
.faqs-section .left-section {
  transform: translateY(-80px);
}
.pulse {
  width: 200px;
  position: absolute;
  z-index: 11;
}
.faq-details-section-container > .super-title {
  color: #3567f3;
  text-transform: uppercase;
  font-weight: 700;
}

.faq-details-section-container > .header {
  font-size: 50px;
  margin-top: 5px;
  line-height: 1.1;
}

.faq-asset-container {
  width: auto;
  height: auto;
}

.faq-asset-container > .top-right-asset-wrapper {
  width: 70%;
  height: 300px;
  border: 1px solid rgb(247, 235, 235);
  border-radius: 100% 100% 0 0;
  overflow: hidden;
}

.faq-asset-container > .top-right-asset-wrapper > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.faq-asset-bottom-container {
  display: flex;
  justify-content: end;
}

.faq-asset-bottom-container > .bottom-left-asset-wrapper {
  width: 70%;
  height: 300px;
  border: 1px solid rgb(247, 235, 235);
  border-radius: 0 0 100% 100%;
  overflow: hidden;
  animation: moveLeftRight 3s infinite;
}

.faq-asset-bottom-container > .bottom-left-asset-wrapper > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes moveLeftRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px); /* Adjust the distance to your preference */
  }
  100% {
    transform: translateX(0);
  }
}

/*latest-project-section  */

.latest-project-section {
  background-color: #171e31;
  height: 100%;
  width: 100%;
  border-radius: 32px;
  padding-bottom: 60px;
  /* background: linear-gradient(#222632e3, #222632e7),
    url("../../../assets/banners/abstractbg.jpg");
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover; */

  background: linear-gradient(#3567f3, #3567f3),
    url("https://html.themeholy.com/webteck/demo/assets/img/bg/testimonial_bg_5.jpg");
  background-blend-mode: hue;
  background-repeat: no-repeat;
  background-size: cover;
}
.latest-project-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.3
  ); /* Adjust the alpha value to control darkness */
  z-index: -1; /* Place the overlay behind the content */
  border-radius: 32px;
}

.latest-project-section .header {
  margin: 0;
  color: white;
  margin-bottom: 30px;
  font-size: 50px;
}

.latest-project-section .super-title {
  color: #3567f3;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.latest-project-section .project-card-container {
  border: 1px solid #edebeb40;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.latest-project-section .project-card-container > .content-container {
  margin: 30px;
}

.latest-project-section
  .project-card-container
  > .content-container
  > .heading {
  color: white;
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 16px;
}

.latest-project-section .project-card-container > .content-container > .text {
  /* color: rgb(143, 139, 139); */
  color: white;
  text-align: justify;
}

.latest-project-section .project-card-container > .content-container > .route {
  /* color: #3567f3; */
  color: white;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  width: 100px;
}
.latest-project-section
  .project-card-container
  > .content-container
  > .route:hover {
  transform: translateY(-3px);
}

.latest-project-section
  .project-card-container
  > .content-container
  > .icon-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 16px;
  background-color: white;
  padding: 30px;
}

.latest-project-section
  .project-card-container
  > .content-container
  > .icon-container
  > .img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 16px;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}
.latest-project-section
  .project-card-container
  > .content-container
  > .icon-container
  > .img:hover {
  transform: scale(1.2);
}

/* work-process-section */

.work-process-section {
  position: relative;
}

.work-process-section .super-title {
  text-align: center;
  margin-bottom: 5px;
  /* color: #3567f3; */
  color: #014065;

  font-weight: 700;
  text-transform: uppercase;
}

.work-process-section .header {
  text-align: center;
  margin: 0;
  font-size: 50px;
  font-weight: 700;
}

.work-process-section .card-container {
  width: 100%;
  height: 100%;
  /* background-color: white; */
  border-radius: 16px;
  box-shadow: 4px 4px 8px 3px rgba(46, 80, 107, 0.13);
  -webkit-box-shadow: 4px 4px 8px 3px rgba(46, 81, 107, 0.13);
  -moz-box-shadow: 4px 4px 8px 3px rgba(46, 81, 107, 0.13);
}

.work-process-section .card-container > .content-container {
  padding: 30px;
  text-align: center;
}

.work-process-section .card-container > .content-container > .heading {
  font-weight: 700;
  font-size: 20px;
}

.work-process-section .card-container > .content-container > .text {
  font-size: 12px;
}

.even-item {
  transform: translateY(-50px);
}

.work-process-section .svg-container {
  width: 100%;
  height: 200px;
  max-width: 100%;
  position: absolute;
  top: 0;
}

.work-process-section .svg-container > .svg {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

/* product section */

.product-section {
  height: 100%;
  width: 100%;
  border-radius: 32px;
  color: white;
}

.product-section .product-section-wrapper {
  height: 500px;
  /* background-color: #3567f3; */
  width: 100%;
  border-radius: 32px;
  color: white;
  /* padding: 30px; */
  /* background-image: url("../../../assets//banners/about_us_img.jpg"); */

  background: linear-gradient(#3567f3, #3567f3),
    url("https://html.themeholy.com/webteck/demo/assets/img/bg/testimonial_bg_5.jpg");
  background-blend-mode: hue;
  background-repeat: no-repeat;
  background-size: cover;
}
.product-section .product-section-wrapper .super-title {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
  color: #021b5f;
}

.product-section .header {
  font-weight: 700;
  font-size: 50px;
  margin-top: 5px;
  max-width: 500px;
  line-height: 1;
  text-transform: capitalize;
}

.product-section .card-container {
  transition: 0.3s ease-in-out;
  border: 1px solid rgba(226, 223, 223, 0.384);
  border-radius: 16px;
  height: 100%;
}
.product-section .card-container:hover {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  box-shadow: 4px 4px 8px 3px rgba(46, 80, 107, 0.13);
  -webkit-box-shadow: 4px 4px 8px 3px rgba(46, 81, 107, 0.13);
  -moz-box-shadow: 4px 4px 8px 3px rgba(46, 81, 107, 0.13);
  transform: translateY(-20px);
  cursor: pointer;
}

.product-section .content-container {
  padding: 30px;
  background-color: white;
  border-radius: 16px;
  height: 100%;
}

.product-section .icon-container {
  height: 200px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}
.product-section .icon-container > .img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}
.product-section .icon-container > .img:hover {
  transform: scale(1.2);
}

.product-section .card-container .content-container .heading {
  color: #222632;
  font-size: 20px;
  font-weight: 700;
}

.product-section .text {
  color: #222632;
  font-size: 16px;
}

/* partners section */
.partners-container {
  height: 100%;
  width: 100%;
  margin-bottom: 150px;
}

/* acticle section */

.article-section {
  height: 100%;
  width: 100%;
  /* background-color: #3567f3; */
  border-radius: 16px;
  box-sizing: border-box;
}

.article-section .card-container {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  box-shadow: 4px 4px 8px 3px rgba(46, 80, 107, 0.13);
  -webkit-box-shadow: 4px 4px 8px 3px rgba(46, 81, 107, 0.13);
  -moz-box-shadow: 4px 4px 8px 3px rgba(46, 81, 107, 0.13);
  transform: translateY(-20px);
  cursor: pointer;
}

.article-section .content-container {
  background-color: white;
  border-radius: 8px;
  height: 100%;
}

.article-section .icon-container {
  height: 200px;
  background-color: white;
  border-radius: 8px 8px 0 0;

  overflow: hidden;
}
.article-section .icon-container > .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}
.article-section .icon-container > .img:hover {
  transform: scale(1.2);
}

.article-section .card-container .content-container .heading {
  color: #222632;
  font-size: 20px;
  font-weight: 700;
  padding: 30px 30px 0 30px;
}

.article-section .text {
  color: #222632;
  font-size: 16px;
  padding: 0 30px 30px 30px;
}

.article-section > .super-title {
  text-align: center;
}

.article-section .header {
  /* text-align: center; */
}

.article-section .header {
  margin-top: 5px;
  font-weight: 700;
  font-size: 42px;
  margin-bottom: 100px;
}
.article-section .super-title {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.super-title {
  color: #3567f3;
  font-size: 24px;
}
