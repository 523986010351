.App {
  text-align: center;
}

body {
  font-family: "Montserat", sans-serif;
}

:root {
  --header: 100px;

  /* color theme */
  --mainColor: #003655;
  --accentColor: #f2e954;

  /* spacing */
  --headerPadding: 16px 8px;
  --headerMargin: 16px 0px;
}

.std-header {
  color: red;
  font-size: var(--header);
}
.std-accent {
  color: var(--accentColor);
}
