.ums_page_main_container .page_banner_container {
  position: relative;
  height: 500px;
  width: 100%;
  max-width: 100%;
  background: rgb(48, 37, 115);

  background: linear-gradient(#3567f3, #3567f3),
    url("../../../../assets//banners//ums_page_banner.jpg");
  background-blend-mode: hue;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-fix-sized-overlay-container {
  position: absolute;
  height: 500px;
  width: 100%;
  background-color: #0a1536bb;
  z-index: 1;
}
.ums_page_main_container .page_banner_container p {
  position: absolute;
  top: 50%;
  left: 45%;
  font-weight: 900;
  font-size: 50px;
  color: white;
  z-index: 2;
}
.pulse-icon-container {
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}
/* .banner-fix-sized-overlay-container {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(5, 8, 54, 0.925);
  z-index: 1;
} */
.left_details_container {
  position: relative;
  width: 100%;
  height: 100%;
}
.left_details_container .long-text {
  text-align: justify;
}
.left_details_container .img-container {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 16px;
}
.cover-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #061c5a77;
  z-index: 1;
  border-radius: 8px;
}

.left_details_container .img-container .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.left_details_container .box-wrapper {
  width: 100%;
  height: auto;
  /* background-color: #c6daf834; */
  background-color: #f5f5f5;

  border-radius: 8px;
  margin-top: 50px;
  padding: 16px 40px 30px 40px;
}
.left_details_container .box-wrapper .heading {
  color: #3567f3;
  font-size: 24px;
}
.left_details_container > .heading {
  color: #061c5a;
}

.box-img-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.box-img-wrapper .img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 16px;
}

#feature-text {
  margin: 0;
  font-weight: 500;
}

/* right section */

.box-wrapper-right {
  width: 100%;
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 8px;
}
.box-wrapper-right h3 {
  padding: 10px 10px 30px 10px;
  margin: 0;
  font-size: 24px;
  color: #061c5a;
}
.sys-func-container {
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 8px;
}
.sys-func-container p {
  font-weight: 500;
}
.sys-func-container .img-container {
  height: 90px;
  padding: 10px 10px;
}
.sys-func-container .img-container .img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}
