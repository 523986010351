.mySwiper {
  margin: 0 25px;
}
.swiper-img-container {
  width: auto;
  height: 130px;
  display: flex;
  justify-content: center !important;
  margin: 0 auto;
}
.swiper-img-container {
  padding: 20px;
}

.swiper-img-container > .img {
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.swiper-img-container > .img:hover {
  transform: scale(1.12) translateY(-10px);
}
.swiper-main-container {
  margin-top: 150px;
  position: relative;
}

.swiper-main-container p {
  display: inline-block;
  text-align: center;
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: 700;
  color: #222632;
  border-bottom: 2px solid #3567f3;
  padding-bottom: 10px;
  font-family: "Barlow", sans-serif;
  margin-top: 10px;
}

.swiper-main-container .super-text-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.swiper-container {
  position: relative;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}
