.banner-main-std-container {
  height: 100vh;
  width: 100vw;
}

.banner-std-container {
  background-color: rgb(51, 78, 153);
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;
}

.banner-std-overlay-container {
  width: 100%;
  height: 100%;
  position: absolute;
  /* background-color: rgba(9, 12, 56, 0.822); */

  /* background-color: rgba(5, 8, 54, 0.822); */
  background-color: rgba(5, 8, 54, 0.925);

  z-index: 1;
}

.banner-std-img-warpper {
  height: 100%;
  width: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.banner-std-img-warpper > .images {
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}
.banner-std-text-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.banner-std-text-container .tagline {
  font-size: 100px;
  max-width: 950px;
  text-align: center;
  line-height: 1;
  margin-top: 10px;
}

.banner-std-text-container .sub-text {
  /* font-size: 24px; */
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 8px;
}

.btn-std-container {
  display: flex;
  align-items: center;
  gap: 70px;
}

/* pulse icon button section styling */
.play-button {
  position: relative;
  width: auto;
  height: auto;
  display: grid;
  place-items: center;
}

.layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff; /* Set your button color */
  border-radius: 50%;
  animation: pulse 4.5s ease-in-out infinite;
  opacity: 0;
}

.layer:nth-child(2) {
  animation-delay: 1.5s;
}

.layer:nth-child(3) {
  animation-delay: 3s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  /* 25% {
    transform: scale(1.5);
    opacity: 0.6;
  } */
  50% {
    transform: scale(2);
    opacity: 0.3;
  }
  /* 75% {
    transform: scale(1.5);
    opacity: 0.3;
  } */
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.play {
  color: #2727d7; /* Set the color of the play button */
  font-size: 12px; /* Set the font size of the play button */
  position: relative;
  z-index: 1; /* Ensure the play button appears above the layers */
}
.pulse-container {
  display: flex;
  align-items: center;
  gap: 30px;
}
