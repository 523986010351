.std-banner-main-container {
  position: relative;
}

.std-banner-container {
  background-color: var(--mainColor);
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  z-index: 12;
}
.brand-warpper {
  height: 300px;
  width: auto;
}

.brand-warpper > .img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.banner-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #47410bee;
  z-index: 1;
}

.std-banner-img-bg-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.std-banner-img-bg-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.std-left-container {
  color: white;
  transform: translateY(100px);
  z-index: 11;
}
.std-left-container .std-tagline {
  font-size: 24px;
  font-style: italic;
  font-weight: 200;
  margin: 0;
}
.std-left-container .std-tagline {
  font-size: 24px;
  font-style: italic;
  font-weight: 200;
  margin: 0;
}

.std-right-container {
  height: 550px;
  transform: translateY(100px);
  z-index: 11;
}
.std-right-container > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
#banner-header-text {
  font-size: var(--header);
  font-weight: 900 !important;
  text-transform: uppercase;
  line-height: 1;
  margin: var(--headerMargin);
}

/* card inside the banner */

.banner-card-container {
  transform: translateY(100px);
  color: white;
  z-index: 12;
}
.banner-card1 {
  background: rgb(48, 37, 115);
  background: linear-gradient(
    90deg,
    rgb(31, 79, 134) 0%,
    rgb(54, 88, 152) 54%,
    rgb(62, 128, 171) 100%
  );
  height: 150px;
  position: relative;
  overflow: hidden;
  transition: 0.3s ease-out;
}
.banner-card1 p {
  transform: translate(25%, 30%);
  font-weight: 900;
  max-width: 250px;
  font-size: 24px;
}
.banner-card1 .circle-object {
  height: 300px;
  width: 300px;

  background-color: rgb(26, 129, 197);
  border-radius: 100%;
  position: absolute;
  right: -25%;
  top: -85%;
}
.banner-card1:hover {
  transform: translateY(-20%);
  cursor: pointer;
}

.banner-card2:hover {
  transform: translateY(-20%);
  cursor: pointer;
}
.banner-card3:hover {
  transform: translateY(-20%);
  cursor: pointer;
}
.banner-card2 {
  background: rgb(48, 37, 115);
  background: linear-gradient(
    90deg,
    rgb(36, 101, 175) 0%,
    rgb(22, 131, 221) 54%,
    rgb(31, 133, 228) 100%
  );
  height: 150px;

  position: relative;
  overflow: hidden;
  transition: 0.3s ease-out;
}
.banner-card2 .circle-object {
  height: 300px;
  width: 300px;

  background-color: rgb(81, 178, 243);
  border-radius: 100%;
  position: absolute;
  right: -25%;
  top: -85%;
}
.banner-card3 {
  background: rgb(48, 37, 115);
  background: linear-gradient(
    90deg,
    rgb(18, 153, 194) 0%,
    rgb(50, 191, 247) 54%,
    rgb(0, 195, 255) 100%
  );
  height: 150px;
  transition: 0.3s ease-out;

  position: relative;
  overflow: hidden;
}

.banner-card3 .circle-object {
  height: 300px;
  width: 300px;

  background-color: rgb(158, 214, 252);
  border-radius: 100%;
  position: absolute;
  right: -25%;
  top: -85%;
}

.banner-card2 p {
  transform: translate(25%, 30%);
  font-weight: 900;
  max-width: 250px;
  font-size: 24px;
}
.banner-card3 p {
  transform: translate(25%, 30%);
  font-weight: 900;
  max-width: 250px;
  font-size: 24px;
}
