.mySwiper {
  margin: 0;
}

#swiper-product-container {
  border-radius: 16px;
  margin: 5px 0;
  padding: 10px 0;
  transition: 0.3s ease-in-out;
  height: 100%;
}

#swiper-product-container .content-container {
  height: 350px;

  border: 1px solid #3568f33b;
}

#swiper-product-container .heading {
  text-wrap: wrap !important;
  overflow: hidden;
}

#swiper-product-container .card-container:hover {
  transform: translateY(-8px) !important;
}
