.primary-image-container {
  width: 100%;
  height: 500px;
  position: relative;
}

.primary-image-container .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.secondary-img-container {
  width: 100%;
  height: 300px;
}

.secondary-img-container .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.benifit-card-container {
  height: 150px;
  width: 100%;
  max-width: 100%;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 20px;
}
.benifit-card-container .icon-container {
  height: 100%;
  width: 200px;
  background-color: rgb(5, 116, 60);
  border-radius: 8px;
  display: grid;
  place-items: center;
}

.benifit-card-container .text-container {
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.benifit-card-container .text-container p,
h3 {
  margin: 0;
}
.benifit-card-container .text-container h3 {
  font-size: 22px;
}

.benifit-card-container .text-container p {
  font-size: 14px;
}

.right-box-container .box-wrapper {
  height: 500px;
  width: 100%;
  background-color: aliceblue;
}
