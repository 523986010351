@media (max-width: 768px) {
  .banner-std-text-container .sub-text {
    /* color: rgb(0, 255, 34); */
    color: white;
    text-align: center;
    margin-top: 0 !important;
  }
  .banner-std-text-container .tagline {
    font-size: 40px;
    padding: 16px;
  }

  .banner-std-text-container .img {
    padding: 16px;
  }
  .core-feature-section p {
    font-size: 16px !important;
  }
  .core-feature-section h3 {
    line-height: 1;
    font-size: 32px !important;
  }

  .latest-project-section {
    border-radius: 0 !important;
  }
  .latest-project-section .header {
    font-size: 24px !important;
  }

  .work-process-section .header {
    line-height: 1 !important;
    font-size: 24px !important;
  }
  .work-process-section .super-title {
    font-size: 16px !important;
  }
  .even-item {
    transform: translateY(0) !important;
  }

  .product-section .product-section-wrapper .super-title {
    font-size: 16px !important;
  }
  .product-section .product-section-wrapper .header {
    font-size: 24px;
  }
  .partners-container {
    margin-bottom: 10px !important;
  }

  .swiper-main-container {
    margin-top: 50px !important;
  }
  .swiper-main-container .super-text-container p {
    margin-bottom: 8px;
    margin-top: 20px !important;
  }

  .swiper-main-container .swiper-container .swiper-img-container > .img {
    padding: 24px !important;
  }
  .footer-main-container {
    height: 100% !important;
    background-repeat: no-repeat !important;
  }

  .footer-image-container {
    width: 120px !important;
    height: 100% !important;
  }

  .footer-image-container > .img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  #swiper-product-container .heading {
    font-size: 24px;
    line-height: 1;
  }
  #swiper-product-container .text {
    font-size: 12px;
  }
}
